<template>
  <div class="w-full h-36 bg-gray-900 relative flex flex-col xl:hidden rounded-lg overflow-hidden">
    <div class="flex w-full h-1/2 relative">
      <div class="absolute left-0 top-0 z-10 h-full w-full map-linear-gradient"></div>
      <img v-if="content.cs_map" class="absolute left-0 top-0 h-full w-full object-cover object-center" :src="`/maps/${content.cs_map.name}_cs2.jpg`">
      <div @click="goToContentPage" class="cursor-pointer flex px-4 flex-col gap-1 justify-center z-20 flex-1">
        <h1 class="text-text-primary font-medium tracking-[.5px] flex items-end">{{ content.title || content.name }}</h1>
        <div class="flex gap-2">
          <div class="flex gap-1 items-center">
            <img v-if="content.cs_map" class="w-4 h-4" :src="`/maps/${content.cs_map.name}_pin.png`"/>
            <h2 v-if="content.cs_map" class="text-text-secondary text-xs">{{ content.cs_map.friendly_name }}</h2>
            <span class="text-text-disabled">•</span>
            <h2 class="text-text-secondary text-xs">Updated {{ moment(content.updated_at).format("MMM Do YYYY") }}</h2>
          </div>
        </div>
      </div>
      <div class="flex gap-3 items-center justify-end z-20 px-4">
        <div
          v-if="loading"
          class="inline-flex h-8 w-8 items-centerborder border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-800 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500 flex justify-center items-center"
        >
          <svg
            class="animate-spin h-full font-normal w-4"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
        <div
          v-else-if="content.is_subscribed || props.isSubscribed"
          @click="unsubscribe"
          class="inline-flex h-8 w-8 items-centerborder cursor-pointer border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-800 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500 flex justify-center items-center"
        >
          <CheckIcon class="w-4 h-4" />
        </div>

        <button
          v-else
          @click="subscribe"
          class="inline-flex h-8 w-8 items-centerborder cursor-pointer border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-800 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500 flex justify-center items-center"
        >
          <PlusCircleIcon class="w-4 h-4" />
        </button>

        <div>
          <button
            @click="launchContent"
            class="inline-flex h-8 w-8 items-centerborder border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:ring-0 focus:ring-offset-0 flex justify-center items-center">
            <svg
              v-if="launchLoading"
              class="animate-spin h-full font-normal w-4"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            <PlayIcon v-else class="w-4 h-4 text-white" />
          </button>
        </div>
      </div>
    </div>
    <div class="w-full h-1/2">
      <div class="h-full flex items-center z-20 flex-1 gap-2">
        <div class="flex flex-col gap-2 justify-center flex-1 items-center">
          <h2 class="text-[10px] tracking-[1.5px] text-text-secondary uppercase">Author</h2>
          <router-link class="flex gap-1 cursor-pointer" :to="`/user/${getProfileLink()}`">
            <img class="h-4 w-4 rounded-full" :src="content.user?.avatarfull">
            <h2 class="text-xs text-text-primary">{{ getUsername() }}</h2>
          </router-link>
        </div>

        <div @click="goToContentPage" class="cursor-pointer flex flex-col gap-2 justify-center flex-1 items-center">
          <h2 class="text-[10px] tracking-[1.5px] text-text-secondary uppercase">Subscribers</h2>
          <h2 class="text-text-primary text-xs">{{ content.subscription_count || 0 }}</h2>
        </div>

        <div @click="goToContentPage" class="cursor-pointer flex flex-col gap-2 justify-center flex-1 items-center">
          <h2 class="text-[10px] tracking-[1.5px] text-text-secondary uppercase">Plays</h2>
          <h2 class="text-text-primary text-xs">{{ content.play_count || 0 }}</h2>
        </div>
      </div>
    </div>
  </div>
  <!-- desktop -->
  <div class="hidden xl:flex h-[76px] w-full bg-gray-900 py-3 px-4 items-center relative">
    <div class="absolute left-0 z-10 h-[76px] w-60 map-linear-gradient"></div>
    <img v-if="content.cs_map" class="absolute left-0 h-[76px] w-60 object-cover" :src="`/maps/${content.cs_map.name}_cs2.jpg`">
    <div @click="goToContentPage" class="cursor-pointer flex flex-col gap-1 justify-center z-20 flex-1">
      <h1 class="text-text-primary font-medium tracking-[.5px] flex items-end">{{ content.title || content.name }}</h1>
      <div class="flex gap-2">
        <div class="flex gap-1 items-center">
          <img v-if="content.cs_map" class="w-4 h-4" :src="`/maps/${content.cs_map.name}_pin.png`"/>
          <h2 v-if="content.cs_map" class="text-text-secondary text-xs">{{ content.cs_map.friendly_name }}</h2>
          <span v-if="content.cs_map" class="text-text-disabled">•</span>
          <h2 class="text-text-secondary text-xs">Updated {{ moment(content.updated_at).format("MMM Do YYYY") }}</h2>
        </div>
      </div>
    </div>

    <div class="h-full flex items-center z-20 flex-1 gap-2">
      <div class="flex flex-col gap-2 justify-center flex-1 justify-between">
        <h2 class="text-[10px] tracking-[1.5px] text-text-secondary uppercase">Author</h2>
        <router-link class="flex gap-1 cursor-pointer" :to="`/user/${getProfileLink()}`">
          <img class="h-4 w-4 rounded-full" :src="content.user?.avatarfull">
          <h2 class="text-xs text-text-primary">{{ getUsername() }}</h2>
        </router-link>
      </div>

      <div @click="goToContentPage" class="cursor-pointer flex flex-col gap-2 justify-center flex-1">
        <h2 class="text-[10px] tracking-[1.5px] text-text-secondary uppercase">Subscribers</h2>
        <h2 class="text-text-primary text-xs">{{ content.subscription_count || content.students_count || 0 }}</h2>
      </div>

      <div v-if="activeMod === 'Routines'" @click="goToContentPage" class="cursor-pointer flex flex-col gap-2 justify-center flex-1">
        <h2 class="text-[10px] tracking-[1.5px] text-text-secondary uppercase">Length</h2>
        <h2 class="text-text-primary text-xs">{{ content.total_time_estimation || 0 }} mins</h2>
      </div>

      <div v-else @click="goToContentPage" class="cursor-pointer flex flex-col gap-2 justify-center flex-1">
        <h2 class="text-[10px] tracking-[1.5px] text-text-secondary uppercase">Plays</h2>
        <h2 class="text-text-primary text-xs">{{ content.play_count || 0 }}</h2>
      </div>
    </div>

    <div class="flex gap-3 items-center justify-end">
      <div
        v-if="loading"
        class="inline-flex h-8 w-8 items-centerborder border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-800 hover:bg-gray-700 focus:outline-none focus:ring-0 focus:ring-offset-0 flex justify-center items-center"
      >
        <svg
          class="animate-spin h-full font-normal w-4"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
      <div
        v-else-if="content.is_subscribed || props.isSubscribed || content.enrolled"
        @click="unsubscribe"
        class="inline-flex h-8 w-8 items-centerborder cursor-pointer border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-800 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500 flex justify-center items-center"
      >
        <CheckIcon class="w-4 h-4" />
      </div>

      <button
        v-else
        @click="subscribe"
        class="inline-flex h-8 w-8 items-centerborder cursor-pointer border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-800 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500 flex justify-center items-center"
      >
        <PlusCircleIcon class="w-4 h-4" />
      </button>
      <button
        @click="launchContent"
        class="inline-flex h-8 w-8 disabled:bg-gray-600 items-centerborder border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:ring-0 focus:ring-offset-0 flex justify-center items-center">
        <svg
          v-if="launchLoading"
          class="animate-spin h-full font-normal w-4"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        <PlayIcon v-else class="w-4 h-4 text-white" />
      </button>
    </div>
  </div>
</template>

<script setup>
import axios from "axios";
import { useStore } from "vuex";
import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";
import moment from "moment";
import { PlusCircleIcon, PlayIcon, CheckIcon, StarIcon } from "@heroicons/vue/solid";

const url = ref(location.host);
const emit = defineEmits(["loadContent", "updateServerInfo"]);
const store = useStore();
const router = useRouter();
const activeTeamId = computed(() => store.state.activeTeam.id);
const loading = ref(false);
const launchLoading = ref(false);
const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
  isSubscribed: {
    type: Boolean,
  },
  modUrl: {
    type: String,
    required: true,
  },
  activeServer: {
    type: Object,
  },
  activeMod: {
    type: Object,
  },
});

function getProfileLink() {
  if (props.content.author_username) {
    return props.content.author_username;
  }
  return props.content.author_uuid;
}

function getUsername() {
  if (props.content.author_username) {
    return props.content.author_username;
  }
  return props.content.user?.personaname;
}

function goToContentPage() {
  if (props.activeMod === "Creator Studio") {
    goToSet();
  } else if (props.activeMod === "Grenade Groups") {
    goToGrenadeGroup();
  } else if (props.activeMod === "Strategies") {
    goToStrategy();
  } else if (props.activeMod === "VPL") {
    goToVPL();
  } else if (props.activeMod === "Routines") {
    router.push({
      name: "RoutinePage",
      params: { slug: props.content.slug },
    });
  }
}

function launchContent() {
  if (!props.activeServer?.id) {
    let serverInfo;
    if (props.activeMod === "Routines") {
      serverInfo = {
        mode: 'Creator Studio',
        content: {
          task_list_id: props.content.id,
          is_exam: false,
          title: props.content.name,
          type: "task",
        },
      }
    } else {
      serverInfo = {
        cs_map: props.content.cs_map,
        mode: props.activeMod,
        content: props.content,
      }
    }
    emit("updateServerInfo", serverInfo);
    return;
  }

  launchLoading.value = true;
  let command = "";
  let value = "";

  if (props.activeMod === "Creator Studio") {
    command = "load_set";
    value = `custom_set, ${props.content.id}`;
  } else if (props.activeMod === "Grenade Groups") {
    command = "load_nadr";
    value = `grenades, ${props.content.id}`;
  } else if (props.activeMod === "Strategies") {
    command = "load_nadr";
    value = `strat, ${props.content.id}`;
  } else if (props.activeMod === "VPL") {
    command = "load_vpl";
    value = `${props.content.id}`;
  } else if (props.activeMod === "Routines") {
    command = "launch_routine";
    value = `${props.content.id}`;
  }

  axios({
    url: `/cs_servers/${props.activeServer.id}/exec_server_command`,
    method: "POST",
    timeout: 5000,
    params: {
      "command": command,
      "value": value,
    },
    headers: {
      "X-TEAM-ID": store.state.activeTeam.id,
    },
  }).then(() => {
    launchLoading.value = false;
  }).catch((err) => {
    store.commit("showMessage", {
      messageType: "error",
      messageText: `Could not execute server command!`,
    });
  });
}

function goToSet() {
  router.push({
    name: "CustomSetPage",
    params: { id: props.content.id },
  });
}

function goToGrenadeGroup() {
  router.push({
    name: "GrenadeGroupPage",
    params: { id: props.content.id },
  });
}

function goToStrategy() {
  router.push({
    name: "StrategyPage",
    params: { id: props.content.id },
  });
}

function goToVPL() {
  router.push({
    name: "VplPage",
    params: { id: props.content.id },
  });
}

function subscribe() {
  loading.value = true;
  if (props.activeMod === 'Routines') {
    axios({
      url: `/task_lists/${props.content.slug}/enroll`,
      method: "POST",
      headers: {
        "X-TEAM-ID": activeTeamId.value,
      },
    }).then(() => {
      emit("loadContent", false);
    });
  } else {
    axios({
      url: `/${props.modUrl}/subscribe`,
      method: "POST",
      params: {
        id: props.content.id,
      },
      headers: {
        "X-TEAM-ID": activeTeamId.value,
      },
    }).then(() => {
      emit("loadContent", false);
    });
  }

}

function unsubscribe() {
  loading.value = true;
  axios({
    url: `/${props.modUrl}/unsubscribe`,
    method: "DELETE",
    params: {
      id: props.content.id,
    },
    headers: {
      "X-TEAM-ID": activeTeamId.value,
    },
  }).then(() => {
    emit("loadContent", false);
  });
}

watch(
  () => props.content.is_subscribed,
  () => {
    loading.value = false;
  }
);

watch(
  () => props.content.enrolled,
  () => {
    loading.value = false;
  }
);

watch(
  () => props.isSubscribed,
  () => {
    loading.value = false;
  }
);
</script>

<style lang="scss" scoped>
.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.map-linear-gradient {
  background: linear-gradient(90deg, rgba(15, 19, 26, 0.70) 0%, #0F131A 100%);
}
</style>
