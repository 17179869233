<script setup>
import { PlayIcon, CheckCircleIcon, XCircleIcon, DownloadIcon } from "@heroicons/vue/outline";
import { integer } from "@vuelidate/validators";
</script>

<template>
  <div class="flex flex-col py-4 gap-4 border-t border-gray-800">
    <div class="flex gap-3 items-center text-text-primary text-sm">
      <input
        type="checkbox"
        :value="checkboxVal"
        v-bind:checked="checkboxVal"
        v-on:input="updateSelected(demo)"
        class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 cursor-pointer"
      />
      <div class="h-4 w-px bg-gray-800"/>
      <img :src="`/maps/${demo.cs_map?.name}_pin.png`" class="h-6 w-6 min-w-6 rounded-full" />
      <span class="text-text-primary text-sm flex-1">{{ demo.cs_map?.friendly_name }}</span>
      <span v-if="validateDemo() && hasTeamScores">
        {{ demo.team_scores.CT }}:{{ demo.team_scores.T }}
      </span>
      <span v-else>?</span>
    </div>
    <span v-if="validateDemo() && hasTeamNames" class="text-text-primary text-sm">
      {{ demo.team_names.CT }}
      vs
      {{ demo.team_names.T }}
    </span>
    <span v-else>?</span>
    <div class="flex gap-3 items-center justify-center">
      <span class="text-text-secondary text-sm flex-1 flex items-center">
        {{ moment(demo.created_at).format("MMM Do YYYY") }}
      </span>
      <a
        class="inline-flex items-center justify-center items-center h-8 w-8 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-800 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
        :href="demo.download_url"
        target="_blank"
        v-if="validateDemo()"
      >
        <DownloadIcon class="w-4 h-4 text-text-primary" />
      </a>
      <a @click="playDemo()" class="cursor-pointer inline-flex items-center justify-center mr-2 items-center w-8 h-8 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500">
        <img src="../../../assets/icons/play.svg" class="w-4 h-4 brightness-200" />
      </a>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapMutations, mapState } from "vuex";
import moment from "moment";

export default {
  name: "DemoRow",
  data() {
    return {
      checkboxVal: false,
    }
  },
  props: {
    demo: {},
    allSelected: Boolean,
    selectedDemos: [],
    numOfDemos: integer,
  },
  watch: {
    //Watching and updating child checkboxes depending on status of checkall checkbox in parent
    allSelected: function (newValue) {
      if (!newValue) {
        this.checkboxVal = false
        this.$emit("remove-selected-demo", this.demo.id);
      } else if (newValue && !this.checkboxVal){
        this.checkboxVal = true
        this.$emit("push-selected-demo", this.demo);
      }
    },
  },
  computed: {
    user() {
      return this.$auth.user() || {};
    },
    team() {
      //Checks to see which team to display based on activeTeamId
      for (var i = 0; i < this.user.teams.length; i++) {
        if (this.user.teams[i].id === Number(localStorage.activeTeamId)) {
          return this.user.teams[i];
        }
      }
      return this.user.teams[0] || {};
    },
    hasTeamNames() {
      return this.demo.team_names && this.demo.team_names.CT && this.demo.team_names.T;
    },
    hasTeamScores() {
      return this.demo.team_scores && this.demo.team_scores.CT && this.demo.team_scores.T;
    },
  },
  methods: {
    updateSelected(demo) {
      this.checkboxVal = !this.checkboxVal
      if (this.checkboxVal) {
        this.$emit("push-selected-demo", demo);
      } else {
        this.$emit("remove-selected-demo", demo.id);
      }
      if (this.allSelected) this.$emit("update-all-selected", false);
    },
    ...mapMutations(["showMessage", "hideMessage"]),
    ...mapState(["activeTeam"]),
    goToDemo: function goToDemo() {
      if (this.demo.status === "broken" || this.demo.status === "analysis_failed") {
        return false;
      }

      return this.$router.push({
        name: "RestratDemos",
        params: { id: this.demo.id },
      });
    },
    playDemo: function playDemo() {
      if (this.demo.status === "broken" || this.demo.status === "analysis_failed") {
        return false;
      }

      return this.$router.push({
        name: "ReviewPage",
        params: { id: this.demo.id },
      });
    },
    showMessagePopup(messageType, messageText, hideAutomatically = false) {
      this.showMessage({ messageType, messageText });
      if (hideAutomatically) {
        setTimeout(this.hideMessage, 3000);
      }
    },
    validateDemo: function validateDemo() {
      return this.demo && this.demo.status === "ready" || this.demo.status === "analyzing" || this.demo.status === "uploaded";
    },
  },
};
</script>

<style lang="scss"></style>
