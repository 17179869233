<template>

  <Dashboard v-if="showUpgrade" title="Utility Hub" no-padding="true">
    <!-- show screenshot and push to upgrade -->
    <div v-if="showUpgrade" class="w-full h-full bg-gray-950 relative flex justify-center items-center">
      <img class="select-none object-cover h-full w-full" src="../assets/utility_hub_preview.png">
      <div class="w-full h-full bg-black/60 z-50 absolute items-center justify-center flex">
        <UpgradeModal
          title="Upgrade to access the Utility Hub"
          firstBenefit="Refrag Academy"
          secondBenefit="2D Demo Viewer"
          primaryPromotionType="utility_hub"
          video_url="https://www.youtube.com/embed/3JJXAMZ7lmw?si=lOLaggrCg_YvcHHA">
        </UpgradeModal>
      </div>
    </div>
  </Dashboard>

  <Dashboard v-else title="Utility Hub" iconsOnly="true" noPadding="true">
    <div class="h-full w-full flex flex-col overflow-hidden">
      <div class="bg-gray-950 px-4 py-6 border-b border-gray-600 sm:px-6">
        <h3 class="text-lg leading-6 font-medium text-white">Utility Hub</h3>
        <p class="text-sm leading-5 text-gray-300">
          Use this new grenade finder tool to see how to throw utility on all CS2 maps!
        </p>
      </div>

      <div class="flex-1 w-full flex flex-col lg:p-4 gap-9">

        <div class="flex px-8 gap-4 xl:px-4 xl:py-0 py-4 flex-wrap">
          <div class="flex-1 flex h-[56px] lg:h-20 rounded-md min-w-[150px]" v-for="map in maps" :key="map">
            <div
              @click="selectMap(map)"
              class="group bg-cover relative duration-300 cursor-pointer w-full h-full rounded-md"
              v-bind:style="{ backgroundImage: 'url(/maps/' + map.name + '_cs2.jpg)' }">
              <div
                :class="[
                  selectedMap == map.name ?
                  'opacity-80 ring-2 ring-primary-600' : 'opacity-90'
                ]"
                class="w-full h-full bg-black rounded-md duration-300"
                >
              </div>
              <div class="absolute flex flex-row items-center px-4 lg:justify-center lg:flex-col w-full h-full top-0 left-0 gap-2">
                <img :src="'/maps/' + map.name + '_pin.png'" class="w-8 h-8">
                <h1 :class="[selectedMap == map.name ? 'text-text-primary' : 'text-text-secondary']">{{ map.friendly_name }}</h1>
              </div>
            </div>
          </div>
        </div>

        <div class="flex w-full px-8 gap-4 md:hidden">
          <div class="flex justify-end cursor-pointer flex-1" v-for="nade in utility" :key="nade">
            <div class="relative p-1 rounded-md hover:scale-125 duration-300 w-full flex items-center justify-center" @click="selectNadeType(nade)"
            :class="[ isNadeSelected(nade) ?
            'border-primary-600 border bg-gray-800' : 'bg-gray-900 border-gray-800 border']">
              <img :src="'/img/icons/' + nade.url" class="h-6 w-6">
            </div>
          </div>
        </div>

        <div class="flex flex-col xl:flex-row flex-1">
          <div class="xl:h-full xl:w-auto w-full aspect-1 items-center xl:px-0 lg:px-8 relative flex-none">
            <div class="relative aspect-1 xl:h-full xl:w-auto w-full bg-grid bg-gray-900 bg-grid" id="canvasParent">
              <div class="xl:h-full xl:w-auto w-full aspect-1 bg-cover"  v-bind:style="{ 'background-image': 'url(/maps/' + selectedMap +'_radar.svg)' }"></div>

              <div v-if="loading" class="absolute top-0 left-0 z-100 w-full h-full flex items-center justify-center text-center">
                <svg class="w-16 h-16 text-white animate-spin" fill="none"
                    viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        fill="currentColor">
                  </path>
                </svg>
              </div>
              <div v-else v-for="grenadeGroup in map_grenades" :key="grenadeGroup" class="absolute rounded-full flex justify-center items-center bg-white/25 hover:bg-white/50 z-10 cursor-pointer w-8 h-8 -translate-x-2/4 -translate-y-2/4" :style="{
                top: -grenadeGroup.points[0].y + '%',
                left: grenadeGroup.points[0].x + '%'
                }"
                @click="selectGrenadeGroup(grenadeGroup)"
                :class="[
                  selectedNadeGroup && selectedNadeGroup.points[0]?.id === grenadeGroup.points[0]?.id ?
                  'bg-white/50' : ''
                ]">
                <img :src="'/img/icons/' + getNade(grenadeGroup.def_id)" class="h-6 w-6">
                <div v-if="grenadeGroup.points.length > 1" class="absolute -right-1 px-1 text-xs -bottom-1 text-white bg-black rounded-full">{{ grenadeGroup.points.length }}</div>
              </div>


              <canvas id="myCanvas" v-show="selectedNadeGroup" class="absolute z-5 w-full h-full top-0 left-0 bg-black/25">
              </canvas>
              <div v-for="grenade in selectedNadeGroup?.points" :key="grenade" class="absolute rounded-full flex justify-center items-center bg-[#687cca] hover:bg-[#556cc6] hover:scale-125 duration-300 z-10 cursor-pointer w-4 h-4 -translate-x-2/4 -translate-y-2/4" :style="{
                top: -grenade.start_y + '%',
                left: grenade.start_x + '%'
                }"
                @click="selectVideo(grenade)"
                @mouseover="hoverVideo(grenade)"
                @mouseleave="unHoverVideo(grenade)">
              </div>

              <div class="hidden absolute w-full right-0 top-0 md:flex py-4 px-2 gap-2 justify-end">
                <div class="flex justify-end cursor-pointer" v-for="nade in utility" :key="nade">
                  <div class="relative p-1 rounded-md hover:scale-125 duration-300" @click="selectNadeType(nade)"
                  :class="[ isNadeSelected(nade) ?
                  'border-primary-600 border bg-gray-800' : 'bg-gray-900 border-gray-800 border']">
                    <img :src="'/img/icons/' + nade.url" class="h-6 w-6">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- videos -->
          <div class="lg:pl-4 w-full flex items-stretch justify-center flex-1 lg:-mt-10 xl:mt-0">
            <div class="flex xl:flex-col gap-4 px-4 flex-1">
              <TransitionGroup tag="div" name="video" class="w-full grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-2 2xl:grid-cols-3 gap-4 min-w-64 overflow-y-auto overflow-x-hidden custom-flex content-start">
                <div v-for="grenade in filteredGrenades" :key="grenade" class="w-full flex flex-col relative group"
                @mouseover="hoverVideo(grenade)"
                @mouseleave="unHoverVideo(grenade)"
                :class="[
                  hoveredVideo == grenade.id ?
                  ' duration-300' : ' duration-150',
                  selectedVideo?.id == grenade.id ?
                  'col-span-2 xl:col-span-2 2xl:col-span-3 row-start-1 duration-400' : 'col-span-1',
                ]">
                  <div v-if="selectedVideo?.id == grenade.id" class="border-2 border-primary-300 rounded-md">
                    <iframe class="w-full aspect-video rounded-t-md" :src="grenade.grenade_video.video_url" allowfullscreen></iframe>
                    <div class="bg-gray-900 p-3 text-left rounded-b-md">
                      <h1 class="text-white font-semibold whitespace-nowrap overflow-hidden overflow-ellipsis">{{ grenade.grenade_video.title }}</h1>
                      <h2 class="text-white font-light text-xs">From {{ grenade.grenade_video.thrown_from }}</h2>
                    </div>
                  </div>
                  <div v-else>
                    <iframe class="w-full aspect-video bg-gray-700 rounded-t-md" :src="grenade.grenade_video.video_url" allowfullscreen></iframe>
                    <div class="bg-gray-900 p-3 text-left px-3 flex flex-col rounded-b-md">
                      <h2 class="text-white font-thin text-text-secondary text-xs">From {{ grenade.grenade_video.thrown_from }}</h2>
                      <h1 class="text-white font-medium whitespace-nowrap overflow-hidden overflow-ellipsis">{{ grenade.grenade_video.title }}</h1>
                    </div>
                    <div class="w-full h-full cursor-pointer absolute top-0 left-0"
                      @click="selectVideo(grenade)"/>
                    </div>
                  </div>
              </TransitionGroup>
            </div>
          </div>
        </div>

      </div>
    </div>
    <VideoPlayerModal v-bind:showVideoModal="showVideoModal" v-bind:video="selectedVideo" @toggleModal="toggleVideoModal()"/>
  </Dashboard>
</template>

<script setup>
import Dashboard from "../components/layouts/DashboardLayout.vue";
import { watch, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "axios";
import { useStore } from "vuex";
import VideoPlayerModal from "../components/VideoPlayerModal.vue";
import groupGrenadesWithinThreshold from "../utils/group_points_utils.js"
import { useAuth } from "@websanova/vue-auth/src/v3";
import UpgradeModal from "../components/UpgradeModal.vue";
import { useHead } from '@unhead/vue'

const store = useStore();
const activeTeam = ref(store.getters.activeTeam);
const searchInput = ref("");
const selectedMap = ref('de_mirage');
const selectedNadeTypes = ref(['43', '44', '45', '46'])
const grenades = ref([]);
const filteredGrenades = ref([]);
const map_grenades = ref([]);
const selectedNadeGroup = ref(null);
const selectedVideo = ref(null);
const hoveredVideo = ref(null);
const showVideoModal = ref(false);
const loading = ref(true);
const route = useRoute();
const router = useRouter();
const maps = ref([]);
const auth = useAuth();
const showUpgrade = ref()
let user = auth.user();

useHead({
  title: 'Refrag | Utility Hub',
})

const utility = ref([
  {
    name: "Smoke",
    url: "smoke.svg",
    def_id: 45
  },
  {
    name: "Molotov",
    url: "molotov.svg",
    def_id: 46
  },
  {
    name: "Flash",
    url: "flash.svg",
    def_id: 43,
  },
  {
    name: "HE Grenade",
    url: "hegrenade.svg",
    def_id: 44
  }
])

if (user.access_level > 1) {
  showUpgrade.value = false;
} else {
  showUpgrade.value = true;
}

if(route.query.selected_map) {
  selectedMap.value = route.query.selected_map;
}

function updateUrlFilters() {
    history.pushState({}, window.location, "?selected_map=" + selectedMap.value + "&nade_id=" + selectedVideo.value?.id)
}

function getGrenades() {
  loading.value = true;
  axios({
    url: `grenade_groups/${getMapGrenadeGroupId()}/grenade_group_with_videos`,
    params: {
      def_id: selectedNadeTypes.value,
    },
    method: "GET",
    }).then((response) => {
      grenades.value = response.data.grenades;
      filteredGrenades.value = grenades.value;
      let tempNades = JSON.parse(JSON.stringify(response.data.grenades))
      map_grenades.value = groupGrenadesWithinThreshold(tempNades);
      if(route.query.nade_id) {
        selectedVideo.value = grenades.value.find((nade) => nade.id == route.query.nade_id);
      }
      loading.value = false;
  })
}

function selectMap(map) {
  selectedMap.value = map.name;
  selectedNadeGroup.value = null;
  getGrenades();
  updateUrlFilters();
}

function getMapGrenadeGroupId() {
  return store.state.siteSettings?.find(setting => setting.name === (selectedMap.value + '_grenade_group_id'))?.value;
}

function getModInfo() {
  maps.value = store.state.modInfo?.mods?.find((mod) => mod.name == 'GrenadeHub')?.cs_maps.sort((a, b) => a.friendly_name.localeCompare(b.friendly_name));
}

function getNade(def_id) {
  return utility.value.find((nade) => nade.def_id === def_id).url;
}

function toggleVideoModal() {
  showVideoModal.value = !showVideoModal.value
}

function selectNadeType(nade) {
  if(selectedNadeTypes.value.includes(nade.def_id.toString())) {
    selectedNadeTypes.value.splice(selectedNadeTypes.value.indexOf(nade.def_id.toString()), 1);
  } else {
    selectedNadeTypes.value.push(nade.def_id.toString());
  }
  selectGrenadeGroup('');
  getGrenades();
}

function isNadeSelected(nade) {
  return selectedNadeTypes.value.includes(nade.def_id.toString());
}

function selectVideo(grenade) {
  selectedVideo.value = grenades.value.find((nade) => nade.id == grenade.id);
  updateUrlFilters();
}

function hoverVideo(grenade) {
  hoveredVideo.value = grenade.id;
}

function unHoverVideo(grenade) {
  hoveredVideo.value = null;
}

function selectGrenadeGroup(group) {
  if (!group || (selectedNadeGroup.value && selectedNadeGroup.value.points[0]?.id == group.points[0]?.id)) {
    selectedNadeGroup.value = null;
    updateVideoFilter();
  } else {
    selectedNadeGroup.value = group;
    updateVideoFilter();
    updateGrenadeCanvas();
  }
}

function updateVideoFilter() {
  if (!selectedNadeGroup.value) {
    filteredGrenades.value = grenades.value
    return;
  }

  let tempGrenades = [];
  selectedNadeGroup.value.points.forEach(selectedNade => {
    let findNade = grenades.value.find((grenade) => grenade.id == selectedNade.id)
    if (findNade) {
      tempGrenades.push(findNade)
    }
  })

  filteredGrenades.value = tempGrenades;
}

function updateGrenadeCanvas() {
  var canvas = document.getElementById("myCanvas");
  var parent = document.getElementById("canvasParent");
  canvas.width = parent.offsetWidth;
  canvas.height = parent.offsetHeight;
  var ctx = canvas.getContext("2d");

  var endX = (selectedNadeGroup.value.points[0].x / 100) * canvas.width;
  var endY = -(selectedNadeGroup.value.points[0].y / 100) * canvas.height;

  selectedNadeGroup.value.points.forEach(grenade => {
    var startX = (grenade.start_x / 100) * canvas.width;
    var startY = -(grenade.start_y / 100) * canvas.height;

    ctx.strokeStyle = "#687cca";
    ctx.lineWidth = 3;
    ctx.setLineDash([10, 5]);

    ctx.beginPath();
    ctx.moveTo(endX, endY);
    ctx.lineTo(startX, startY);
    ctx.stroke();
  });
}

watch(
  () => store.state.modInfo.mods, () => {
    if(!showUpgrade.value) getModInfo();
  }
);

watch(
  () => store.state.siteSettings, () => {
    if(!showUpgrade.value) getGrenades();
  }
);

// This is needed because if a user refreshes the page, the active team isnt there when the API call is made
watch(
  () => store.getters.activeTeam,
  (currentValue) => {
    activeTeam.value = currentValue;
  }
);

// The timeout is so the user can finish typing before a bunch of api calls are made
watch(
  () => searchInput.value,
  () => {
    if (loading.value) return;
    loading.value = true;

    setTimeout(() => {
      loading.value = false;
      page.value = 1;
    }, 500);
  }
);
</script>

<style lang="scss" scoped>
.custom-flex {
  flex: 1 1 1px;
}
.aspect-video {
  aspect-ratio: 16 / 9;
}
.bg-grid {
  background-image: radial-gradient(circle, #06090c 1px, rgba(0, 0, 0, 0) 1px);
  background-size: 30px 30px;
}
.width-transition {
  display: block;
  transition: all, 0.5s ease-in-out;
  width: 0;
}

.width-transition:focus {
  width: 16rem;
}
.video-move, /* apply transition to moving elements */
.video-enter-active,
.video-leave-active {
  transition: transform 0.5s ease;
}

.video-enter-from,
.video-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.video-leave-active {
}
</style>
