import { createRouter, createWebHistory } from 'vue-router'
import Home from '/src/views/HomePage.vue'
import Login from '/src/views/LoginPage.vue'
import Restrat from '/src/views/Restrat/DemosPage.vue'
import RestratDemos from '/src/views/Restrat/DemoPage.vue'
import UpgradePage from '/src/views/UpgradePage.vue'
import RegisterPage from '../views/RegisterPage.vue'
import ResetPassword from '../views/ResetPasswordPage.vue'
import ChooseNewPassword from '../views/ChooseNewPassword.vue'
import SettingsPage from '../views/SettingsPage.vue'
import LeaderboardsPage from '../views/LeaderboardsPage.vue'
import TeamPage from '../views/TeamPage.vue'
import TeamInvitePage from '../views/TeamInvitePage.vue'
import RedeemPage from '../views/RedeemPage.vue'
import AcademyPage from '../views/AcademyPage.vue'
import SteamLinkErrorPage from '../views/SteamLinkErrorPage.vue'
import CommunityHubPage from '../views/CommunityHub/CommunityHubPage.vue'
import CustomContentPage from '../views/CommunityHub/CustomContentPage.vue'
import UserProfile from '../views/UserProfilePage.vue'
import ServerManagerPage from '../views/ServerManagerPage.vue'
import UtilityHubPage from '../views/UtilityHubPage.vue'
import TrackWarmupPage from '../views/Public/TrackWarmup.vue'
import CommunityServersPage from '../views/CommunityServersPage.vue'
import DemoViewerPage from "../views/DemoViewerPage.vue"
import DemoViewerUpgradePage from "../views/DemoViewerUpgradePage.vue"
import BootcampsPage from "../views/Bootcamp/BootcampsPage.vue"
import BootcampPage from "../views/Bootcamp/BootcampPage.vue"
import BootcampExamPage from "../views/Bootcamp/BootcampExamPage.vue"
import RoutinesPage from "../views/Routines/RoutinesPage.vue"
import RoutinePage from "../views/Routines/RoutinePage.vue"
import HighlightPage from "../views/HighlightPage.vue"
import ChangeEmailPage from '../views/ChangeEmailPage.vue'
import UpgradeStatusPage from '../views/UpgradeStatusPage.vue'
import BootcampExamSharePage from '../views/Bootcamp/BootcampExamSharePage.vue'
import RoutineEditPage from '../views/Routines/RoutineEditPage.vue'

const routes = [
    {
        path: '/',
        name: 'Dashboard',
        component: Home,
        meta: {
            auth: true,
        },
    },
    {
        path: '/user/:uuid',
        name: 'UserProfile',
        component: UserProfile,
        meta: {
            auth: true,
        },
    },
    {
        path: '/team',
        name: 'Team',
        component: TeamPage,
        meta: {
            auth: true,
        },
    },
    {
        path: '/team_invites/:token',
        name: 'Team Invite',
        component: TeamInvitePage,
        meta: {
            auth: true,
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            auth: false,
        }
    },
    {
        path: '/register',
        name: 'Register',
        component: RegisterPage,
        meta: {
            auth: false,
        }
    },
    {
        path: '/reset_password',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: {
            auth: false,
        }
    },
    {
        path: '/change_email/:token',
        name: 'ChangeEmail',
        component: ChangeEmailPage,
    },
    {
        path: '/choose_new_password',
        name: 'ChooseNewPassword',
        component: ChooseNewPassword,
        meta: {
            auth: false,
        }
    },
    {
        path: '/demos',
        name: 'Demos',
        component: Restrat,
        meta: {
            auth: true,
        }
    },
    {
        path: '/demo_viewer_upgrade',
        name: 'DemoViewerUpgrade',
        component: DemoViewerUpgradePage,
        meta: {
            auth: true,
        }
    },
    {
        path: '/upgrade_status',
        name: 'UpgradeStatus',
        component: UpgradeStatusPage,
        meta: {
            auth: true,
        }
    },
    {
        path: '/restrat/demos/:id',
        name: 'RestratDemos',
        component: RestratDemos,
        meta: {
            auth: true,
        }
    },
    {
        path: '/community_hub/:type',
        name: 'CommunityHubPage',
        component: CommunityHubPage,
        meta: {
            auth: true,
        }
    },
    {
        path: '/community_hub/custom_sets/:id',
        name: 'CustomSetPage',
        component: CustomContentPage,
        meta: {
            auth: true,
        },
    },
    {
        path: '/community_hub/grenade_groups/:id',
        name: 'GrenadeGroupPage',
        component: CustomContentPage,
        meta: {
            auth: true,
        },
    },
    {
        path: '/community_hub/strategy/:id',
        name: 'StrategyPage',
        component: CustomContentPage,
        meta: {
            auth: true,
        },
    },
    {
        path: '/community_hub/vpl/:id',
        name: 'VplPage',
        component: CustomContentPage,
        meta: {
            auth: true,
        },
    },
    {
        path: '/bootcamp',
        name: 'BootcampsPage',
        component: BootcampsPage,
        meta: {
            auth: true,
        }
    },
    {
        path: '/routines',
        name: 'RoutinesPage',
        component: RoutinesPage,
        meta: {
            auth: true,
        }
    },
    {
        path: '/routines/:slug',
        name: 'RoutinePage',
        component: RoutinePage,
        meta: {
            auth: true,
        }
    },
    {
        path: '/routines/:slug/edit',
        name: 'RoutineEditPage',
        component: RoutineEditPage,
        meta: {
            auth: true,
        }
    },
    {
        path: '/bootcamp/:slug',
        name: 'BootcampPage',
        component: BootcampPage,
        meta: {
            auth: true,
        }
    },
    {
        path: '/bootcamp/:slug/exam/:difficulty',
        name: 'BootcampExamPage',
        component: BootcampExamPage,
        meta: {
            auth: true,
        }
    },
    {
        path: '/bootcamp/:slug/share_exam/:id',
        name: 'BootcampExamSharePage',
        component: BootcampExamSharePage,
    },
    {
        path: '/upgrade',
        name: 'UpgradePage',
        component: UpgradePage,
        meta: {
            auth: true
        }
    },
    {
        path: '/redeem',
        name: 'Redeem',
        component: RedeemPage,
        meta: {
            auth: true
        }
    },
    {
        path: '/settings',
        name: 'SettingsPage',
        component: SettingsPage,
        meta: {
            auth: true
        }
    },
    {
        path: '/leaderboards',
        name: 'Leaderboards',
        component: LeaderboardsPage,
        meta: {
            auth: true,
        }
    },
    {
        path : '/academy/videos/:slug',
        name: 'VideoPage',
        component: AcademyPage,
        meta: {
            auth: true,
        }
    },
    {
        path : '/academy',
        name: 'AcademyPage',
        component: AcademyPage,
        meta: {
            auth: true,
        }
    },
    {
        path: '/server_manager',
        name: 'ServerManagerPage',
        component: ServerManagerPage,
        meta: {
            auth: true,
        }
    },
    {
        path: '/utility_hub',
        name: 'UtilityHubPage',
        component: UtilityHubPage,
        meta: {
            auth: true,
        }
    },
    {
        path: '/community_servers',
        name: 'CommunityServersPage',
        component: CommunityServersPage,
    },
    {
        path: '/highlights/:uuid',
        name: 'HighlightPage',
        component: HighlightPage,
    },
    {
        path: '/demos/:id/review',
        name: 'ReviewPage',
        component: DemoViewerPage,
        meta: {
            auth: true,
        }
    },
    {
        path: '/steam-link-error',
        name: 'Steam Link Error',
        component: SteamLinkErrorPage,
        meta: {
            auth: true,
        }
    },{
        path: '/high_scores/public_warmup',
        name: 'Public Warmup',
        component: () => import('../views/Public/WarmupScores.vue'),
        meta: {
            auth: false,
        }
    },
    {
        path: '/track_warmup_usage/:steam_id',
        name: 'Track Warmup',
        component: TrackWarmupPage,
        meta: {
            auth: false,
        }
    },
    {
        path: '/steam/callback',
        name: 'SteamCallback',
        component: () => import('../views/SteamCallback.vue'),
        meta: {
            auth: false,
        }
    },
    { path: '/:pathMatch(.*)*', redirect: '/' },
]
const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router
