<template>
  <div v-if="props.match" class="flex">
    <div
      class="w-[2px] h-[120px] md:h-[72px]"
      :class="[props.match.match_result === 'win' ? 'bg-success-400' : 'bg-danger-400']"
    />
    <div
      class="flex w-full gap-4"
      :class="[
        props.match.match_result === 'win' ? 'bg-success-gradient' : 'bg-danger-gradient',
      ]"
    >
      <div class="hidden md:flex w-full items-center ml-4 gap-4">
        <div class="flex relative">
          <img
            class="w-12 h-12 rounded-md object-cover opacity-[25%]"
            :src="`/maps/${convertMapName(props.match.map_name)}_cs2.jpg`"
          />
          <img
            class="w-8 h-8 rounded-md object-cover absolute top-0 left-0 translate-x-1/4 translate-y-1/4"
            :src="`/maps/${convertMapName(props.match.map_name)}_pin.png`"
          />
        </div>
        <div class="flex gap-6 flex-1">
          <div class="flex-1 flex flex-col gap-1">
            <h1 class="text-text-primary font-medium">
              <span
                :class="[props.match.match_result === 'win' ? 'text-success-400' : '']"
                >{{ getUserTeamScore() }}</span
              >
              -
              <span
                :class="[props.match.match_result === 'loss' ? 'text-danger-400' : '']"
                >{{ getOpponentTeamScore() }}</span
              >
            </h1>
            <div class="flex gap-1">
              <img
                class="w-4 h-4 object-cover"
                :src="`/img/icons/${props.match.platform}_icon.png`"
              />
              <h2 class="text-text-secondary text-xs">
                {{ convertPlatformName(props.match.platform) }}
              </h2>
            </div>
          </div>

          <div class="flex-1 flex-col flex gap-1 justify-center">
            <h1 class="text-text-secondary text-xs uppercase tracking-[1.5px]">Date</h1>
            <h2 class="text-text-primary text-sm">
              {{
                props.match.played_on
                  ? moment(props.match.played_on).format("MMM Do YYYY")
                  : "-"
              }}
            </h2>
          </div>
          <div class="flex-1 flex-col flex gap-1">
            <h1 class="text-text-secondary text-xs uppercase tracking-[1.5px]">
              K / D / A
            </h1>
            <h2 class="text-text-primary text-sm">
              {{ props.match.user_stats.kills == null ? '?' : props.match.user_stats.kills }} /
              {{ props.match.user_stats.deaths == null ? '?' : props.match.user_stats.deaths }} /
              {{ props.match.user_stats.assists == null ? '?' : props.match.user_stats.assists }}
            </h2>
          </div>
          <div class="flex-1 flex-col flex gap-1">
            <h1 class="text-text-secondary text-xs uppercase tracking-[1.5px]">HS%</h1>
            <h2 class="text-text-primary text-sm">
              {{ props.match.user_stats.headshot_percentage == null ? '?' : props.match.user_stats.headshot_percentage }}%
            </h2>
          </div>
          <div class="flex-1 flex-col flex gap-1">
            <h1 class="text-text-secondary text-xs uppercase tracking-[1.5px]">KPR</h1>
            <h2 class="text-text-primary text-sm">
              {{ props.match.user_stats.kills_per_round == null ? '?' : props.match.user_stats.kills_per_round}}
            </h2>
          </div>
          <div class="w-24 flex-col items-center justify-center flex gap-1">
            <button class="bg-gray-800 rounded-lg py-2 px-2 text-text-primary text-xs hover:bg-gray-700 duration-300" @click="emit('toggleAllstar', props.match.allstar_clip_uuid)" v-if="props.match.allstar_clip_uuid">
              <img src="../../assets/icons/AllstarLight.svg" class="w-4 h-4" />
            </button>
          </div>
        </div>
      </div>

      <div class="flex md:hidden w-full items-center ml-4 gap-4">
        <div class="flex gap-2 py-3 flex-1 flex-col md:flex-row">
          <div class="flex gap-4">
            <div class="flex relative">
              <img
                class="w-12 h-12 rounded-md object-cover opacity-[25%]"
                :src="`/maps/${convertMapName(props.match.map_name)}_cs2.jpg`"
              />
              <img
                class="w-8 h-8 rounded-md object-cover absolute top-0 left-0 translate-x-1/4 translate-y-1/4"
                :src="`/maps/${convertMapName(props.match.map_name)}_pin.png`"
              />
            </div>
            <div class="flex flex-col gap-1">
              <h1 class="text-text-primary font-medium">
                <span
                  :class="[props.match.match_result === 'win' ? 'text-success-400' : '']"
                  >{{ getUserTeamScore() }}</span
                >
                -
                <span
                  :class="[props.match.match_result === 'loss' ? 'text-danger-400' : '']"
                  >{{ getOpponentTeamScore() }}</span
                >
              </h1>
              <div class="flex gap-1">
                <img
                  class="w-4 h-4 object-cover"
                  :src="`/img/icons/${props.match.platform}_icon.png`"
                />
                <h2 class="text-text-secondary text-xs">
                  {{ convertPlatformName(props.match.platform) }}
                </h2>
              </div>
            </div>

            <div class="flex-col flex gap-1 justify-center">
              <h1 class="text-text-secondary text-xs uppercase tracking-[1.5px]">Date</h1>
              <h2 class="text-text-primary text-sm">
                {{ moment(props.match.played_on).format("MMM Do YYYY") }}
              </h2>
            </div>

            <div class="w-24 flex-col items-center justify-center flex gap-1">
              <button class="bg-gray-800 rounded-lg py-2 px-2 text-text-primary text-xs hover:bg-gray-700 duration-300" @click="emit('toggleAllstar', props.match.allstar_clip_uuid)" v-if="props.match.allstar_clip_uuid">
                <img src="../../assets/icons/AllstarLight.svg" class="w-4 h-4" />
              </button>
            </div>
          </div>

          <div class="flex gap-2">
            <div class="flex-1 flex-col flex gap-1">
              <h1 class="text-text-secondary text-xs uppercase tracking-[1.5px]">
                K / D / A
              </h1>
              <h2 class="text-text-primary text-sm">
                {{ props.match.user_stats.kills }} / {{ props.match.user_stats.deaths }} /
                {{ props.match.user_stats.assists }}
              </h2>
            </div>
            <div class="flex-1 flex-col flex gap-1">
              <h1 class="text-text-secondary text-xs uppercase tracking-[1.5px]">HS%</h1>
              <h2 class="text-text-primary text-sm">
                {{ props.match.user_stats.headshot_percentage }}%
              </h2>
            </div>
            <div class="flex-1 flex-col flex gap-1">
              <h1 class="text-text-secondary text-xs uppercase tracking-[1.5px]">KPR</h1>
              <h2 class="text-text-primary text-sm">
                {{ props.match.user_stats.kills_per_round }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else></div>
</template>

<script setup>
import moment from "moment";

const emit = defineEmits(['toggleAllstar']);

const props = defineProps({
  match: {
    type: Object,
    required: true,
  },
});

function convertMapName(name) {
  return name?.includes("wm_") ? name.replace("wm_", "de_") : name;
}

function convertPlatformName(name) {
  return name === "faceit" ? "Faceit" : "Steam";
}

function getUserTeamScore() {
  if (!props.match?.score) return 0;

  if (props.match.match_result === "win") {
    return Math.max(props.match.score[0], props.match.score[1]);
  } else {
    return Math.min(props.match.score[0], props.match.score[1]);
  }
}

function getOpponentTeamScore() {
  if (!props.match?.score) return 0;

  if (props.match.match_result === "win") {
    return Math.min(props.match.score[0], props.match.score[1]);
  } else {
    return Math.max(props.match.score[0], props.match.score[1]);
  }
}
</script>

<style scoped>
.bg-success-gradient {
  background: linear-gradient(90deg, rgba(10, 72, 64, 0.2) 0%, rgba(10, 72, 64, 0) 100%);
}
.bg-danger-gradient {
  background: linear-gradient(
    90deg,
    rgba(103, 19, 19, 0.2) 0%,
    rgba(103, 19, 19, 0) 100%
  );
}
</style>
