import { createStore } from 'vuex';
import createPersistedState from "vuex-persistedstate";

export const store = createStore({
	state: () => ({
    alertMessage: {
      messageType: "",
      messageText: "",
      show: false,
    },
    activeTeam: null,
    modInfo: null,
    servers: null,
    activeServer: null,
  }),

  getters: {
    activeTeam(state) {
      return state.activeTeam
    },

    selectedGame(state) {
      return state.selectedGame
    },

    servers(state) {
      return state.servers
    },

    activeServer(state) {
      return state.activeServer
    }
  },

  mutations: {
      showMessage( state, {messageType, messageText} ){
        state.alertMessage.messageType = messageType;
        state.alertMessage.messageText = messageText;
        state.alertMessage.show = true;
      },

      hideMessage( state ){
          state.alertMessage.show = false;
      },

      setActiveTeam( state, team ){
        state.activeTeam = team;
      },

      setSelectedGame( state, value ) {
        state.selectedGame = value
      },

      setModInfo( state, modInfo ){
        state.modInfo = modInfo
      },

      setServers( state, servers){
        state.servers = servers
      },

      setActiveServer( state, server){
        state.activeServer = server
      },

      updateActiveTeamData( state, auth ){
        auth.fetch().then(() => {
          const user = auth.user();
          const activeTeam = user.teams.find( team => team.id === state.activeTeam.id );
          state.activeTeam = activeTeam;
        })
      },
  },
  plugins: [createPersistedState({ storage: window.sessionStorage })],
});
