<template>
    <div @click="emit('updateOnboardingStep', { value: props.currentStep - 1 })" class="cursor-pointer hover:scale-105 duration-300 rounded-md bg-gray-800 px-3 py-2.5 text-text-primary flex justify-center items-center text-xl absolute top-8 left-4 lg:left-16">
        <img class="w-4 mr-1" src="../../assets/icons/leftarrow.svg">
        <h1 class="text-xs text-text-primary">Back</h1>
    </div>
    <div class="flex flex-col h-full items-center lg:justify-center w-full mt-16 lg:mt-0 lg:w-[414px]">
        <h1 class="text-text-primary text-[36px] font-bold pb-3">Connect Accounts</h1>
        <h2 class="text-text-secondary text-center pb-6">Get ready to unlock a world of seamless training! <br/>To ensure a personalized and tailored experience, connect your Steam account now.</h2>
        <div class="flex flex-col gap-6 w-full">
            <div class="w-full flex flex-col rounded-md border border-gray-800 p-6">
                <div class="flex w-full items-center">
                    <h1 class="text-text-primary text-xl font-medium flex-1">Steam</h1>
                    <Pill class="px-2 py-[2px]" text="Required" bgColor="#EF6B6B20" textColor="#EF6B6B" />
                </div>
                <h2 class="text-text-secondary font-light text-sm pt-3 pb-9">We need your SteamID in order to whitelist you on our servers</h2>
                <div v-if="!user.steam_id">
                    <form action="https://steamcommunity.com/openid/login" method="post">
                        <input
                        type="hidden"
                        name="openid.identity"
                        value="http://specs.openid.net/auth/2.0/identifier_select"
                        />
                        <input
                        type="hidden"
                        name="openid.claimed_id"
                        value="http://specs.openid.net/auth/2.0/identifier_select"
                        />
                        <input
                        type="hidden"
                        name="openid.ns"
                        value="http://specs.openid.net/auth/2.0"
                        />
                        <input type="hidden" name="openid.mode" value="checkid_setup" />
                        <input
                        type="hidden"
                        name="openid.return_to"
                        :value="`https://${getEnvVar()}api.refrag.gg/auth/${$auth.user().sgid}/steam?redirect_url=https://${getEnvVar()}play.refrag.gg/`"
                        />
                        <button
                        type="submit"
                        class="py-2 w-full rounded-md bg-primary-600 hover:bg-primary-500 duration-300 flex items-center justify-center gap-2 h-[42px] text-sm text-text-primary"
                        >
                            <img src="../../assets/icons/steam.svg">
                            Connect Steam
                        </button>
                    </form>
                </div>
                <div v-else class="flex h-[42px] gap-3 items-center">
                    <img :src="user.steam_profile?.avatarfull" class="rounded-full h-[42px] w-[42px]">
                    <div class="flex flex-col flex-1">
                        <h1 class="text-text-primary font-lighter">{{ user.steam_profile?.personaname }}</h1>
                        <h2 class="text-text-secondary font-light text-xs">{{ user.steam_profile?.steamid }}</h2>
                    </div>
                    <div @click="clearSteamId" class="cursor-pointer h-[42px] w-[42px] rounded-md bg-gray-800 flex items-center justify-center">
                        <img src="../../assets/icons/trash.svg">
                    </div>
                </div>
            </div>
            <div class="w-full flex flex-col rounded-md border border-gray-800 p-6">
                <div class="flex w-full items-center">
                    <h1 class="text-text-primary text-xl font-medium flex-1">Faceit</h1>
                    <Pill class="px-2 py-[2px]" text="Optional" bgColor="#131A20" textColor="#9CADC4" />
                </div>
                <h2 class="text-text-secondary font-light text-sm pt-3 pb-9">Enter your faceit username so we can track your matches. <span class="italic text-xs">*Case sensitive</span></h2>
                <div v-if="!savedFaceitNickname" class="flex gap-3">
                    <input
                        @input="faceitNickname = $event.target.value"
                        class="block flex-1 rounded-md border-gray-700 bg-gray-800 h-[42px] text-text-secondary text-sm"
                        placeholder="Enter your Faceit nickname"
                        type="text"
                        :value="faceitNickname"
                    />
                    <Transition>
                        <button
                            type="button"
                            @click="saveFaceitNickname"
                            class="bg-primary-600 rounded-md h-[42px] w-[42px] flex text-text-primary items-center justify-center duration-300 disabled:bg-primary-600/30 disabled:text-text-primary/30"
                            :disabled="!faceitNickname"
                            >
                            <CheckCircleIcon v-if="!faceitLoading" class="h-6 w-6"/>
                            <div v-else>
                                <svg
                                    class="animate-spin h-6 w-6 text-text-primary"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                    class="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    stroke-width="4"
                                    ></circle>
                                    <path
                                    class="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0
                                    12h4zm2 5.291A7.962 7.962 0 014 12H0c0
                                    3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                            </div>
                        </button>
                    </Transition>
                </div>
                <div v-else>
                    <div class="flex gap-3 items-center">
                        <h1 class="text-text-primary font-lighter flex-1">{{ savedFaceitNickname }}</h1>
                        <div @click="clearFaceitNickname" class="cursor-pointer h-[42px] w-[42px] rounded-md bg-gray-800 flex items-center justify-center">
                            <img src="../../assets/icons/trash.svg">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button :disabled="!user.steam_id" @click="continueAndSubmit()" class="cursor-pointer group w-full py-3 text-text-primary disabled:text-text-primary/30 bg-primary-600 disabled:bg-primary-600/30 hover:bg-primary-500 mt-10 rounded-md flex items-center justify-center gap-1">
            Continue
            <img class="h-4 brightness-200 group-disabled:brightness-50" src="../../assets/icons/rightarrow.svg" alt="Continue" />
        </button>
    </div>
    <AlertPopup />
</template>

<script setup>
import Pill from '../Pill.vue';
import { onMounted, ref } from 'vue';
import { useAuth } from '@websanova/vue-auth/src/v3';
import { Transition } from 'vue';
import axios from 'axios';
import { CheckCircleIcon } from '@heroicons/vue/solid';
import { useRoute, useRouter } from 'vue-router';
import AlertPopup from '../AlertPopup.vue';
import { useStore } from 'vuex';

const auth = useAuth();
const user = ref(auth.user());
const store = useStore();
const faceitNickname = ref(user.value?.faceit_nickname || "");
const faceitLoading = ref(false);
const emit = defineEmits(['updateOnboardingStep']);
const savedFaceitNickname = ref(user.value?.faceit_nickname || "");
const route = useRoute();
const router = useRouter();

const props = defineProps({
    currentStep: Number
});

function saveFaceitNickname() {
    faceitLoading.value = true;
    axios({
        method: "put",
        url: "/users/update_user",
        data: {
            faceit_nickname: faceitNickname.value,
        },
    }).then((res) => {
        user.value = res.data.data;
        faceitLoading.value = false;
        savedFaceitNickname.value = faceitNickname.value;
        auth.fetch();
    });
}

function clearFaceitNickname() {
    savedFaceitNickname.value = "";
}

function clearSteamId() {
    axios({
        method: "put",
        url: "/users/clear_steam_id",
    }).then((res) => {
        user.value = res.data.data
    });
}

function continueAndSubmit() {
    emit('updateOnboardingStep', { value: props.currentStep + 1 });
    if (faceitNickname.value && faceitNickname.value !== user.value.faceit_nickname) {
        saveFaceitNickname();
    }
}

function getEnvVar() {
    if (import.meta.env.VITE_ENVIRONMENT) return import.meta.env.VITE_ENVIRONMENT
    return "";
}

function showSteamIdError() {
  store.commit("showMessage", {messageType: "error", messageText: "Steam ID already in use. To login with that Steam ID, please logout from this account, and login via the Steam button."})
}

onMounted(() => {
    if (route.query.error === "steam_id_in_use") {
        showSteamIdError();
        router.replace({query: {}});
    }
});

</script>